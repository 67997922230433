import Http from "../utils/Http";
import * as action from "../store/actions";

let BASE_API_URL
let API_URL;

if(process.env.REACT_APP_ENVIRONMENT === 'development') {
	BASE_API_URL = process.env.REACT_APP_TEST_BASE_API_URL;
	API_URL = process.env.REACT_APP_TEST_API_URL;
} else {
	BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
	API_URL = process.env.REACT_APP_API_URL;
}

export function login(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/auth/login", credentials)
			.then(res => {
				return resolve(res.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
				// console.clear();
	});
}

export function logout() {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/logout")
			.then(res => {
				return resolve(res.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
				// console.clear();
	});
}

export function refreshToken() {
	let credentials = {
		grant_type: "refresh_token",
		refresh_token: localStorage.getItem('refresh_token')
	}
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/auth/refreshToken", credentials)
				.then(res => {
					dispatch(action.authRefreshToken(res.data));
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

//註冊--------------------------------------------------------------------------------------------
export function registerPhoneVerify(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/register/phone", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

export function registerSubmit(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/register", credentials)
			.then(response => {
				return resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}


//忘記密碼取得手機驗證碼---------------------------------------------------------------------------
export function forgotPhoneVerify(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/forgot/phone", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

//取得信箱驗證碼---------------------------------------------------------------------------
export function forgotEmailVerify(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/forgot/email", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

//送出驗證碼-----------------------------------------------------------------------------------
export function forgotVerify(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/forgot/verify", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

//忘記密碼修改---------------------------------------------------------------------------
export function forgotSubmit(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/forgot", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

export function fetchUser() {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.get(API_URL+"/user")
				.then(res => {
					dispatch(action.authUser(res.data));
					return resolve();
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err);
					}
				});
		});
}

//Banner----------------------------------------------------------------------------------

export function getBannerList() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/carousel")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

//----------------------------------------------------------------------------------------

//Profile----------------------------------------------------------------------------------
export function updateProfile(credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
			Http.post(API_URL+"/user", credentials, config)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}
//-----------------------------------------------------------------------------------------

//ApiManage----------------------------------------------------------------------------------

export function getExchangeApiStatus(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/exchange/api/status", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function huobiConfigSubmit(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/huobi/config", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getHuobiConfig(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/huobi/config", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function binanceConfigSubmit(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/binance/config", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBinanceConfig(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/binance/config", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

//-----------------------------------------------------------------------------------------

//Notification----------------------------------------------------------------------------------

export function getAllNotification() {
	return new Promise((resolve, reject) => {
			Http.get(API_URL+"/user/notification")
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}

export function getNotification(id) {
	return new Promise((resolve, reject) => {
			Http.get(API_URL+"/user/notification/"+id)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}

export function readAllNotification() {
	return new Promise((resolve, reject) => {
			Http.put(API_URL+"/user/notification/read")
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}

//-----------------------------------------------------------------------------------------

//News----------------------------------------------------------------------------------

export function getAllNews(credentials) {

	return new Promise((resolve, reject) => {
			Http.post(API_URL+"/news", credentials)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}

export function getNewsItem(id) {

	return new Promise((resolve, reject) => {
			Http.get(API_URL+"/news/"+id)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}
//-----------------------------------------------------------------------------------------

//Language----------------------------------------------------------------------------------
export function updateLanguage(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/user/language", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-----------------------------------------------------------------------------------------

//Password----------------------------------------------------------------------------------
export function updatePassword(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/user/password", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-----------------------------------------------------------------------------------------

//Email----------------------------------------------------------------------------------
export function emailVerify(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/verify/email/send", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

export function emailVerifySubmit(credentials) {
	return new Promise((resolve, reject) => {
			Http.post(API_URL+"/verify/email", credentials)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}
//-----------------------------------------------------------------------------------------

//Identity----------------------------------------------------------------------------------
export function getIdentity() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/verify/identity")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function identityVerify(credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
			Http.post(API_URL+"/verify/identity", credentials, config)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}
//-----------------------------------------------------------------------------------------

//Bank----------------------------------------------------------------------------------
export function getBank() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/verify/payments/bank")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function bankVerify(credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
			Http.post(API_URL+"/verify/payments/bank", credentials, config)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}
//-----------------------------------------------------------------------------------------

//ERC20----------------------------------------------------------------------------------
export function getERC20() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/verify/payments/blockchain")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function erc20Verify(credentials) {
	return new Promise((resolve, reject) => {
			Http.post(API_URL+"/verify/payments/blockchain", credentials)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}
//-----------------------------------------------------------------------------------------


//bid--------------------------------------------------------------------------------------
export function getBid(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/bid", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-----------------------------------------------------------------------------------------