import React from "react";
import { render } from 'react-dom';
import store from './js/store';
import { authCheck } from './js/store/actions';
import AuthService from './js/services';
import App from './App';
import './App.scss';
import * as serviceWorker from './serviceWorker';

store.dispatch(authCheck());

if (localStorage.getItem('jwt_token')) {
    // store.dispatch(AuthService.users());
}

render(
    <App />,
    document.getElementById('app')
);

serviceWorker.unregister();